import { useRef } from 'react';

import cn from 'classnames';

import { useIntersection } from 'hooks/useIntersection';

import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

export const CustomError = ({ statusCode }: { statusCode: number | string }) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  return (
    <div className="custom-error">
      <div ref={ref} className={cn('error-bg-rectangles', intersected && 'intersected')} />

      <Icon type={[404, 500].includes(Number(statusCode)) ? statusCode.toString() : '500'} />

      <span>{"We're sorry, but something went wrong."}</span>

      <Button size={64} href={`${process.env.HOST || ''}/`}>Go to homepage</Button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CustomError;
