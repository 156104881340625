import { Navigation } from 'libs/http/api/common/common.types';

import { NavigationProps } from 'stores/content/content.types';

import { $Object } from './object.types';

export const isObject = (value: any): value is Record<any, any> => {
  const type = typeof value;
  return value !== null && Array.isArray(value) === false && (type === 'object' || type === 'function');
};

export function keys<O extends object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export const mapPageToNavigationItem = (page: Navigation): NavigationProps => ({
  navigationId: page.navigation_id || '',
  title: page.menu_title || page.title || '',
  description: page.menu_description || page.description || '',
  icon: page.menu_icon || '',
  slug: page.is_home_page ? '' : page.slug,
  pageType: page.page_type || '',
  showInHeader: Boolean(page.show_in_header),
  showInFooter: Boolean(page.show_in_footer),
  isProduct: Boolean(page.is_product),
  hasAboutUsInformation: Boolean(page.has_about_us_information),
  hasIntegrationInformation: page.has_integration_information ? page.has_integration_information.type : '',
  hasFaq: Boolean(page.has_frequency_questions),
  headerMenuPosition: page.header_menu_position || 0,
  isSolution: Boolean(page.is_solution),
  region: (page.has_country_information && page.has_country_information.region) || '',
  isPopular: Boolean(page.has_country_information && page.has_country_information.is_popular),
  hasCountryInformation: Boolean(page.has_country_information),
  hasIndustryInformation: Boolean(page.has_industry_information),
});

export const parseObject = (obj: $Object<string>): string => {
  const str = keys(obj).reduce<string[]>((acc, current) => [...acc, `${current}=${obj[current]}`], []);
  return `${str.length > 0 ? '?' : ''}${str.join('&')}`;
};

export function removeFalsy<T>(obj: T): NonNullable<T> | undefined {
  if (Array.isArray(obj)) {
    // If obj is an array, recursively call removeFalsy for each element
    return obj.map(removeFalsy).filter(Boolean) as any;
  } else if (typeof obj === 'object' && obj !== null) {
    // If obj is an object, recursively call removeFalsy for each property
    const filteredObj = Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, removeFalsy(value)])
        .filter(([_, value]) => !!value),
    );
    return filteredObj as any;
  }
  // If obj is not an array or object, return the value if truthy, otherwise undefined
  return obj || undefined;
}
