import cn from 'classnames';

type Props = {
  className?: string;
  loading?: boolean;
  size?: 'small' | 'regular';
  color?: 'white' | 'regular';
};

export const LoaderSpin = ({ className = '', loading = true, size = 'regular', color = 'regular' }: Props) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={cn('lds-ring', `lds-ring-${size}`, `lds-ring-color-${color}`, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
