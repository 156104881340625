import { useMemo } from 'react';

import { useRouter } from 'libs/router/useRouter';

export const useIsIframe = () => {
  const router = useRouter();

  return {
    isIframe: useMemo(() => router.query['for-public-page'] === 'true', [router]),
  };
};
