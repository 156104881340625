import { $Object } from 'libs/object/object.types';

import { keys } from './object/object';

export type Languages = keyof typeof languageNames;

export const defaultLocale = 'en';

export const languageNames = {
  [defaultLocale]: 'English',
  es: 'Spanish',
  it: 'Italian',
  fr: 'French',
};

export const locales = keys(languageNames);

export const localizedHome: $Object<string> = {
  [defaultLocale]: 'home',
  es: 'home-es',
  it: 'home-it',
  fr: 'home-fr',
};
