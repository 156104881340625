/* eslint react-hooks/exhaustive-deps: "warn" */

import { useMemo, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { sanitizeHTML } from 'libs/string';
import { clearTimeoutIfExists } from 'libs/node';

import { useIntersection } from 'hooks/useIntersection';

import type { Cache, Props } from './Icon.types';

const regexWithViewBox = /<svg\s[^>]*\bviewBox="([^"]*)"[^>]*>([\s\S]*?)<\/svg>/;
const regexWithoutViewBox = /<svg\s[^>]*>([\s\S]*?)<\/svg>/;

let cache: Cache = {};
let clearCacheTimer: NodeJS.Timeout;
const clearCache = () => {
  cache = {};
};

const request = async (url: string) => {
  clearTimeoutIfExists(clearCacheTimer);
  clearCacheTimer = setTimeout(clearCache, 1000 * 60);

  const cachedData = cache?.[url]?.data;
  if (cachedData) {
    return cachedData;
  }

  return new Promise((resolve, reject) => {
    if (url in cache === false) {
      cache[url] = {};
    }

    const cached = cache[url];

    if (cached && cached.pending && cached.promise) {
      return cached.promise.then(resolve).catch(reject);
    }

    cached.pending = true;
    cached.promise = fetch(url)
      .then((response) => {
        cached.pending = false;

        const contentType = response.headers.get('content-type');
        const [fileType] = (contentType ?? '').split(/ ?; ?/);

        if (response.status > 299 || !['image/svg+xml', 'text/plain'].some((d) => fileType.includes(d))) {
          throw new Error('Unexpected error occurred.');
        }

        const text = response.text();
        cached.data = text;

        return text;
      })
      .catch((err) => {
        cached.pending = false;
        return Promise.reject(err);
      });

    return cached.promise?.then(resolve).catch(reject);
  });
};

export const Icon = ({ disabled, style, className = '', type = '', size = 'small', onClick, url }: Props) => {
  const ref = useRef<SVGSVGElement>(null);
  const intersected = useIntersection(ref);

  const [state, setState] = useState({
    hasStroke: false,
    viewBox: '',
    path: '',
  });

  useEffect(() => {
    if (intersected === false) {
      return;
    }

    (async () => {
      try {
        const svgString = await request(url || `/storage/svg/base/${type}.svg`);

        if (typeof svgString !== 'string') {
          return;
        }

        let [, viewBox, path] = regexWithViewBox.exec(sanitizeHTML(svgString)) || [];

        if (!viewBox || !path) {
          viewBox = '0 0 16 16';
          [, path] = regexWithoutViewBox.exec(sanitizeHTML(svgString)) || [];
        }

        if (!path) {
          throw new Error('SVG data extraction failed.');
        }

        setState({ hasStroke: svgString.includes('stroke='), viewBox, path });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, [type, intersected, url]);

  const defaultProps = useMemo(
    () => ({
      width: '1em',
      height: '1em',
      style,
      className: cn(
        'gdb-icon',
        `gdb-icon-size-${size}`,
        state.hasStroke && 'gdb-icon-stroked',
        disabled && 'disabled',
        type && `gdb-${type} gdb-icon-${type}`,
        className,
      ),
      onClick,
      fill: state.hasStroke ? 'none' : undefined,
    }),
    [state.hasStroke, type, size, className, style, disabled, onClick],
  );

  if (!type && !url) {
    return null;
  }

  return (
    <svg
      ref={ref}
      viewBox={state.viewBox || undefined}
      dangerouslySetInnerHTML={{ __html: state.path }}
      {...defaultProps}
    />
  );
};
