import { CSSProperties, ReactNode, useRef, useCallback, useState, useEffect, useMemo } from 'react';

import NextLink from 'next/link';
import cn from 'classnames';

import { $Object } from 'libs/object/object.types';
import { defaultLocale } from 'libs/locale';
import { clearTimeoutIfExists } from 'libs/node';

import { useIsIframe } from 'hooks/useIsIframe';

import { useRouter } from './useRouter';

interface LinkProps {
  scroll?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  route?: string;
  rel?: string;
  params?: $Object<any>;
  query?: $Object<any>;
  onClick?: () => any;
  light?: boolean;
  children?: ReactNode | ((loading: boolean) => ReactNode);
  tabIndex?: number;
  property?: string; // used for structured data
  typeOf?: string; // used for structured data
  ariaLabel?: string;
  id?: string;
}

const customersTypesWithUnderscore: { [key: string]: string } = {
  category_1: 'sales-and-marketing',
  category_2: 'compliance',
  category_3: 'data-enrichment',
};

export const Link = ({
  scroll = true,
  disabled,
  className = '',
  route,
  property,
  typeOf,
  ariaLabel = '',
  params = {},
  id,
  children,
  onClick,
  ...props
}: LinkProps) => {
  const { query } = useRouter();
  const { isIframe } = useIsIframe();
  const loadingTimer = useRef<NodeJS.Timeout>();

  const [ssr, setSSR] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSSR(false);

    return () => {
      clearTimeoutIfExists(loadingTimer.current);
    };
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && 'page' in (props.query || {})) {
      /**
       * We've been going through some links refactoring
       * And the only one way to not mix old ones with new was to rename the properties
       * */
      throw new Error("Use key 'paage' instead of 'page'. Read the comment above the code for more details ..");
    }
  }, [props.query]);

  const onClickHandler = useCallback(() => {
    setLoading(true);

    clearTimeoutIfExists(loadingTimer.current);
    loadingTimer.current = setTimeout(() => {
      setLoading(false);
    }, 1000);

    if (typeof onClick === 'function') {
      return onClick();
    }
  }, [onClick]);

  const [lang, hasLang] = useMemo(() => {
    const $lang = params.lang || query.lang || defaultLocale;
    return [$lang, $lang.length === 2 && $lang !== defaultLocale];
  }, [params, query]);

  const linkProps = useMemo(() => {
    const initialProps = {
      scroll,
      prefetch: false,
    };

    if (route && ['blog', 'customers'].includes(route)) {
      const type = props.query?.type
        ? route === 'customers'
          ? customersTypesWithUnderscore?.[props.query.type]
          : props.query.type.replace(/_/g, '-')
        : undefined;

      const routeParts = [
        hasLang ? `${lang}` : '',
        route,
        type,
        Boolean(props.query?.paage) === false || props.query?.paage === 1
          ? undefined
          : `page/${props.query?.paage || 1}`,
      ];

      return {
        ...initialProps,
        href: `/${routeParts.filter((i) => i).join('/')}`,
      };
    }

    return {
      ...initialProps,
      href: { query: props.query, pathname: `/${route ? '[...route]' : ''}` },
      as: {
        query: props.query,
        pathname: `${hasLang ? `/${lang}` : ''}${hasLang === false && Boolean(route) === false ? '/' : ''}${
          route ? `/${route}` : ''
        }`,
      },
    };
  }, [scroll, props.query, route, hasLang, lang]);

  const structuredDataProps = useMemo(
    () => (property && typeOf ? { property, typeof: typeOf } : {}),
    [property, typeOf],
  );

  const renderedChildren = useMemo(() => {
    if (typeof children === 'function') {
      return children(loading);
    }

    return children;
  }, [children, loading]);

  if (disabled) {
    return <span className={cn(className, 'link-disabled')}>{renderedChildren}</span>;
  }

  if (ssr) {
    const url = ('as' in linkProps ? linkProps.as.pathname : linkProps.href) || '';

    return (
      <a href={`${process.env.HOST || ''}${url}`} style={props.style} className={cn(className, props.light && 'light')}>
        {renderedChildren}
      </a>
    );
  }

  return (
    <NextLink
      {...linkProps}
      {...structuredDataProps}
      tabIndex={0}
      role="link"
      id={id}
      className={cn(className, props.light && 'light')}
      style={props.style}
      onClick={onClickHandler}
      rel={props.rel}
      target={isIframe ? '_blank' : '_self'}
      aria-label={ariaLabel}
    >
      {renderedChildren}
    </NextLink>
  );
};
