import type { FilterFunc } from 'rc-select/lib/interface/generator';

import type { $Object } from './object/object.types';

export const isVideo = (path: string): boolean => (typeof path === 'string' && path.match(/.mp4$/) !== null) || false;

export const isSvg = (path: string): boolean => (typeof path === 'string' && path.match(/.svg$/) !== null) || false;

const splitString = (delimiter: string, str: string): string[] =>
  str.split(delimiter).reduce<string[]>((acc1, current1, index, array) => {
    acc1.push(current1);

    if (array.length !== index + 1) {
      acc1.push(delimiter);
    }

    return acc1;
  }, []);

export const split = (delimiter: string, str: string | string[]): string[] => {
  if (Array.isArray(str)) {
    const strClone = [] as string[];

    str.forEach((item) => strClone.push(...splitString(delimiter, item)));

    return strClone;
  }

  return splitString(delimiter, str);
};

export const getTextSize = <T>(text: T): typeof size => {
  let size: 'big' | 'regular' | 'small' | 'super-small' = 'regular';

  if (typeof text === 'string' || typeof text === 'number') {
    const textLength = `${text}`.length;

    if (textLength >= 1) {
      size = 'big';
    }

    if (textLength >= 4) {
      size = 'regular';
    }

    if (textLength >= 10) {
      size = 'small';
    }

    if (textLength >= 50) {
      size = 'super-small';
    }
  }

  return size;
};

export const isNotBusinessEmail = (email: string) =>
  [
    /@googlemail\.[A-zZ-a].+$/,
    /@gmail\.[A-zZ-a].+$/,
    /@mail\.[A-zZ-a].+$/,
    /@yahoo\.[A-zZ-a].+$/,
    /@rambler\.[A-zZ-a].+$/,
    /@gmx\.[A-zZ-a].+$/,
    /@hotmail\.[A-zZ-a].+$/,
    /@live\.[A-zZ-a].+$/,
    /@aol\.[A-zZ-a].+$/,
    /@outlook\.[A-zZ-a].+$/,
    /@msn\.[A-zZ-a].+$/,
    /@me\.[A-zZ-a].+$/,
    /@icloud\.[A-zZ-a].+$/,
    /@126\.[A-zZ-a].+$/,
    /@163\.[A-zZ-a].+$/,
    /@qq\.[A-zZ-a].+$/,
    /@naver\.[A-zZ-a].+$/,
    /@consultant\.[A-zZ-a].+$/,
    /@rocketmail\.[A-zZ-a].+$/,
    '@yandex.ru',
    '@bk.ru',
    '@list.ru',
  ].reduce((acc, current) => {
    acc = acc || email.toLowerCase().trim().match(current) !== null;

    return acc;
  }, false);

export const capitalize = (value: string) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const parseWebsite = (website: string) => {
  if (website) {
    return website.replace('www.', '').replace('https://', '').replace('http://', '').replace('/', '').toLowerCase();
  }

  return '';
};

export const arrayRandElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const currencySymbol = (string?: string) => {
  if (typeof string !== 'string') {
    return '';
  }

  const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
  } as $Object;

  if (string in currencySymbols) {
    return currencySymbols[string];
  }

  return string;
};

export const filterOptions: FilterFunc<any> = (input: string, option): boolean => {
  /** Ensure input and option's children are strings */
  if (typeof input !== 'string' || typeof option?.key !== 'string') {
    return false;
  }

  /** Convert both input and option text to lowercase for case-insensitive comparison */
  return option.key.toLowerCase().trim().indexOf(input.toLowerCase().trim()) >= 0;
};

export const sanitizeHTML = (HTMLString: string) => {
  // Define a list of disallowed elements
  const disallowedElements = ['script', 'iframe', 'embed'];

  // Replace any disallowed elements with an empty string
  disallowedElements.forEach((element) => {
    const regex = new RegExp(`<${element}.*?</${element}>`, 'gis');
    HTMLString = HTMLString.replace(regex, '');
  });

  return HTMLString;
};
