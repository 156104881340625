import { useMemo, useEffect } from 'react';

import { NextRouter, useRouter as useOldRouter } from 'next/router';

import { $Object } from 'libs/object/object.types';
import { defaultLocale, locales } from 'libs/locale';

export const useRouter = (): Pick<NextRouter, Exclude<keyof NextRouter, 'push'>> & { push: typeof push } => {
  const { push: oldPush, ...router } = useOldRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && 'page' in (router.query || {})) {
      /**
       * We've been going through some links refactoring
       * And the only one way to not mix old ones with new was to rename the properties
       * */
      throw new Error("Use key 'paage' insead of 'page'. Read the comment above the code for more details ..");
    }
  }, [router.query]);

  const query = useMemo(() => {
    if (router.query.route?.length === 1) {
      return locales.includes(router.query.route[0] as (typeof locales)[0])
        ? { lang: router.query.route[0] }
        : { lang: defaultLocale, category: router.query.route[0] };
    }

    if (router.query.route?.length === 2) {
      return { lang: router.query.route[0], category: router.query.route[1] };
    }

    return {};
  }, [router.query]);

  const push = (route = '', params: $Object, query = {} as $Object) => {
    const hasLang = params.lang.length === 2 && params.lang !== defaultLocale;

    const [hrefPathname, asPathname] = [
      `/${route ? '[...route]' : ''}`,
      `${hasLang ? `/${params.lang}` : ''}${!hasLang && !route ? '/' : ''}${route ? `/${route}` : ''}`,
    ];

    return oldPush({ query, pathname: hrefPathname }, { query, pathname: asPathname });
  };

  return { ...router, push, query: { ...router.query, ...query } };
};
